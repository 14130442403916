


























































































import { Component, Mixins } from 'vue-property-decorator'

import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import isEmpty from 'lodash/isEmpty'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import RichTextDisplay from '@/partials/components/RichTextDisplay.vue'

@Component({
  components: {
    RichTextDisplay,
    ArrowDown16,
    ArrowUp16,
    EventUserDropdown,
    PasswordConfirmField,
    GenericModalDelete,
    GenericModalForm,
    TrashCan16,
    Ticket16,
    Edit16
  }
})
export default class EventSchedules extends Mixins(FlashMessageMixin, PermissionsMixin) {
  // @Prop({ required: true, type: String }) eventId!: string
  eventId = this.$route.params.id
  eventSchedules = []
  deleteModalIsOpened = false
  selectedSchedule = {
    id: '',
    datetime: '',
    title: '',
    description: ''
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/schedules`)
      .then(response => {
        this.eventSchedules = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  moveChoice (index: number, direction: string) {
    const targetIndex = (direction === 'up') ? index - 1 : index + 1

    const swappingChoice = this.eventSchedules[targetIndex]

    this.eventSchedules.splice(targetIndex, 1)
    this.eventSchedules.splice(index, 0, swappingChoice)

    this.submitOrder()
  }

  submitOrder () {
    const orderedSchedules = this.eventSchedules.map((schedule: any, index: number) => {
      return { id: schedule.id, order: index }
    })

    const submitForm = {
      schedule: orderedSchedules
    }

    axios.put(`event/${this.eventId}/schedules/reorder`, submitForm)
      .then((response) => {
        this.eventSchedules = camelCaseKeys(response.data.data).map((schedule: any, index: number) => {
          schedule.order = index
          return schedule
        })
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Programação foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  hasDisplayableContent (html: string) {
    return Boolean(html?.length >= 10)
  }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedSchedule) ? '' : this.selectedSchedule.id] }
  }

  get deleteUrl () {
    return isEmpty(this.selectedSchedule) ? '' : `/event/${this.eventId}/schedules/${this.selectedSchedule.id}`
  }
}
